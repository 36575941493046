<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-card>
        <v-card-text>
          <v-container>
            <v-tabs v-model="tab" centered>
              <v-tabs-slider></v-tabs-slider>
              <v-tab class="no-uppercase" href="#tab-1">
                Dados Principais
              </v-tab>
              <v-tab class="no-uppercase" href="#tab-2">Endereço </v-tab>
              <v-tab class="no-uppercase" href="#tab-3">Complemento </v-tab>
            </v-tabs>
            <br />
            <v-tabs-items v-model="tab">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-tab-item :value="'tab-1'">
                  <v-row>
                    <v-col cols="12" sm="8">
                      <v-text-field
                        outlined
                        label="Nome Completo"
                        v-model="owner.person.corporateName"
                        :rules="[(v) => !!v || 'Campo obrigatório']"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        outlined
                        :items="cells"
                        item-value="id"
                        item-text="name"
                        v-model="owner.groupId"
                        label="Grupo/Célula"
                        :loading="loading"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" class="mt-n8">
                      <v-text-field
                        outlined
                        v-mask="maskCpf"
                        label="CPF"
                        v-model="owner.person.idDocument"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="mt-n8">
                      <v-text-field
                        outlined
                        label="RG/SSP"
                        v-model="owner.person.stateDocument"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="3" class="mt-n8">
                      <v-text-field
                        outlined
                        v-mask="maskPhone"
                        label="Fone Fixo"
                        v-model="owner.contact.phoneLandline"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="mt-n8">
                      <v-text-field
                        outlined
                        v-mask="maskPhoneMobile"
                        label="Celular"
                        v-model="owner.contact.phoneMobile"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="mt-n8">
                      <v-text-field
                        outlined
                        v-mask="maskPhoneMobile"
                        label="WhatsApp"
                        v-model="owner.contact.whatsApp"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5" class="mt-n8">
                      <v-text-field
                        outlined
                        label="Email"
                        v-model="owner.contact.email"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="mt-n8">
                      <v-text-field
                        outlined
                        v-money="money"
                        label="Renda Mensal R$"
                        v-model.lazy="owner.person.salaryOrInvoice"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="mt-n8">
                      <v-text-field
                        outlined
                        label="Profissão"
                        v-model="owner.person.professionOrMainActivity"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="mt-n8">
                      <v-text-field
                        outlined
                        label="Empresa Empregadora"
                        v-model="owner.person.workCompany"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      :disabled="!valid"
                      :loading="loadingAction"
                      width="200px"
                      color="#2ca01c"
                      dark
                      @click="validate()"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
                <v-tab-item :value="'tab-2'">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        outlined
                        v-mask="'#####-###'"
                        label="CEP"
                        v-model="owner.address.zipCode"
                        required
                        @change="searchAddress()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        label="Logradouro"
                        v-model="owner.address.street"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field
                        outlined
                        label="Número"
                        v-model="owner.address.number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="mt-n8">
                      <v-text-field
                        outlined
                        label="Complemento"
                        v-model="owner.address.complement"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" class="mt-n8">
                      <v-text-field
                        outlined
                        label="Bairro"
                        v-model="owner.address.zone"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="mt-n8">
                      <v-text-field
                        outlined
                        label="Cidade"
                        v-model="owner.address.city"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" class="mt-n8">
                      <v-text-field
                        outlined
                        label="UF"
                        v-model="owner.address.state"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mt-n8">
                      <v-textarea
                        outlined
                        label="Observações"
                        :rows="5"
                        v-model="owner.notes"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      dark
                      :disabled="!valid"
                      :loading="loadingAction"
                      width="200px"
                      color="#2ca01c"
                      @click="validate()"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
                <v-tab-item :value="'tab-3'">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-select
                        outlined
                        v-model="owner.person.gender"
                        :items="genders"
                        item-text="text"
                        item-value="value"
                        label="Sexo"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-select
                        outlined
                        v-model="owner.person.maritalStatus"
                        :items="['Casado(a)', 'Solteiro(a)']"
                        label="Estado Cívil"
                        required
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        label="Conjuge"
                        v-model="owner.person.conjuge"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="mt-n8">
                      <v-text-field
                        outlined
                        label="Nome do Pai"
                        v-model="owner.person.fatherName"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="mt-n8">
                      <v-text-field
                        outlined
                        label="Nome da Mãe"
                        v-model="owner.person.motherName"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="mt-n8">
                      <v-text-field
                        outlined
                        type="date"
                        label="Data Nascimento"
                        v-model="owner.person.birthDate"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="mt-n8">
                      <v-text-field
                        outlined
                        type="date"
                        label="Data Conversão"
                        v-model="owner.conversionDate"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="mt-n8">
                      <v-text-field
                        outlined
                        type="date"
                        label="Data Batismo"
                        v-model="owner.integrationDate"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="mt-n8">
                      <v-autocomplete
                        outlined
                        :items="members"
                        item-value="id"
                        item-text="person.corporateName"
                        v-model="owner.originId"
                        label="Ganho Por"
                        :loading="loading"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="6" class="mt-n8">
                      <v-autocomplete
                        outlined
                        :items="members"
                        item-value="id"
                        item-text="person.corporateName"
                        v-model="owner.leaderId"
                        label="Discipulador"
                        :loading="loading"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      :disabled="!valid"
                      :loading="loadingAction"
                      width="200px"
                      color="#2ca01c"
                      dark
                      @click="validate()"
                    >
                      Salvar
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
              </v-form>
            </v-tabs-items>
          </v-container>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { VMoney } from "v-money";
import SearchAddressService from "@/services/address.service";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";

export default Vue.extend({
  name: "Company",
  directives: { money: VMoney },
  components: { InternalMenu, PageHeader },
  data: () => ({
    title: "Membros",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Aniversariantes",
            to: "/customers/birthdays",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Grupos/Células",
            to: "/promise/cells",
            permission: "promise.cells.get_many",
          }, 
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Membros",
        disabled: false,
        href: "/members",
      },
      {
        text: "Cadastro Membro",
        disabled: true,
        href: "/members",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    tab: null,
    zipCode: "",
    personTypes: [
      {
        id: "FISICA",
        label: "Física",
      },
      {
        id: "JURIDICA",
        label: "Juridica",
      },
    ],
    cells: [],
    members: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    maskPhone: "(##)####-####",
    maskCpf: "###.###.###-##",
    maskCnpj: "##.###.###/####-##",
    maskPhoneMobile: "(##)#####-####",
    valid: true,
    owner: {
      active: true,
      groupId: null,
      notes: "",
      leaderId: null,
      originId: null,
      conversionDate: null,
      integrationDate: null,
      person: {
        corporateName: "",
        tradeName: "",
        idDocument: "",
        personType: "FISICA",
        stateDocument: "",
        gender: "",
        maritalStatus: "",
        professionOrMainActivity: "",
        salaryOrInvoice: "",
        workCompany: "",
        fatherName: "",
        motherName: "",
        conjugeDocument: "",
        birthDate: null,
      },
      address: {
        street: "",
        number: "",
        complement: "",
        zone: "",
        city: "",
        state: "",
        zipCode: "",
      },
      contact: {
        email: "",
        phoneLandline: "",
        phoneMobile: "",
        whatsApp: "",
      },
    },
    id: "",
    genders: [
      {
        value: "F",
        text: "Feminino",
      },
      {
        value: "M",
        text: "Masculino",
      },
      {
        value: "N",
        text: "Não Informado",
      },
    ],
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "person.corporateName",
        filtering: true,
      },
      {
        text: "Celular",
        align: "start",
        value: "contact.phoneMobile",
        filtering: true,
      },
      {
        text: "Grupo/Célula",
        align: "start",
        value: "groyp.name",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },

    async searchAddress() {
      const zipCode = this.owner.address.zipCode.replace(/([^\d])+/gim, "");
      const res = await SearchAddressService(zipCode);
      if (res.success) {
        res.address.zipCode = this.owner.address.zipCode;
        this.owner.address = res.address;
      } else {
        this.snackbar.text = "Houve erro ao buscar o CEP";
        this.snackbar.color = "orange";
        this.snackbar.opened = true;
      }
    },

    getItem() {
      const id = this.$route.params.id;
      if (id) {
        this.loading = true;
        this.id = id;
        http.get(`/gerentor/customers/${id}`).then(
          (res) => {
            const { data } = res;
            this.owner = data;
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    },
    registerOrUpdate() {
      this.loadingAction = true;
      let monetary = this.owner.person.salaryOrInvoice
        .toString()
        .replace("R$ ", "");
      monetary = monetary.replace(".", "");
      monetary = monetary.replace(",", ".") * 1;
      this.owner.person.salaryOrInvoice = monetary;
      if (this.id) {
        http.put(`gerentor/customers/${this.id}`, this.owner).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.$router.push("/members");
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("gerentor/customers", this.owner).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.$router.push("/members");
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },

    getCells() {
      this.loading = true;
      http.get("promise/cells?filter=type||eq||group").then(
        (data) => {
          this.cells = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },

    getMembers() {
      this.loading = true;
      http.get("gerentor/customers").then(
        (data) => {
          this.members = data.data;
          if (this.id)
            this.members = this.members.filter((a) => a.id !== this.id);
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
  },
  mounted() {
    this.getMembers();
    this.getCells();
    this.getItem();
  },
});
</script>

<style>
.label-outlined {
  font-size: 12px;
  color: grey;
}
</style>
